import { Component, input, signal } from "@angular/core"
import { RouterLink } from "@angular/router"
import { IntroLegacyCardData } from "../intro.model"
import { NgOptimizedImage } from "@angular/common"

@Component({
  imports: [
    RouterLink,
    NgOptimizedImage,
  ],
  providers: [],
  standalone: true,
  template: `
    @if (cardData(); as data) {
      <div
        style="width: 250px; position: relative; cursor: pointer"
        [style.margin-left.px]="data.style.marginLeft"
        [routerLink]="[]"
        [fragment]="'legacy-for-today-' + data.type.current.toLowerCase()"
        queryParamsHandling="preserve"
        (mouseover)="hover.set(true)"
        (mouseout)="hover.set(false)"
      >
        <img
          [ngSrc]="data.image.src"
          [alt]="data.image.altText"
          style="border-radius: 15px; position: relative"
          [style.filter]="!hover() ? 'brightness(120%)' : ''"
          fill=""
        >
        <div style="position: absolute; bottom: 20px; left: 20px; color: white; font-size: 28px; font-weight: 900">
          {{ data.title }}
        </div>
      </div>
    }
  `,
  selector: "e2e-intro-legacy-card",
})
export class IntroLegacyCardComponent {
  cardData = input.required<IntroLegacyCardData>()
  hover = signal(false)
}
