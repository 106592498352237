import { Component, input } from "@angular/core"

@Component({
  imports: [
  ],
  standalone: true,
  template: `
    {{ header() }}
  `,
  selector: "e2e-user-header",
})
export class UserHeaderComponent {
  header = input.required<string>()

}
