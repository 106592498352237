import { Component, computed, inject } from "@angular/core"
import { EditorLocationComponent } from "./fields/editor-location.component"
import { EditorLayoutLayerComponent } from "./fields/editor-layout-layer.component"
import { EditorLayoutPositionComponent } from "./fields/editor-layout-position.component"
import { EditorLayoutRotationComponent } from "./fields/editor-layout-rotation.component"
import { EditorLayoutWidthComponent } from "./fields/editor-layout-width.component"
import { EditorInteractiveDetailsComponent } from "./fields/editor-interactive-details.component"
import { EditorClassificationComponent } from "./fields/editor-classification.component"
import { EditorService } from "./services/editor.service"
import { Classification } from "../content/content.model"
import { EditorContentComponent } from "./editor-content.component"
import { RouteService } from "../../services/route.service"
import { UiCollapseComponent } from "ui/collapse"
import { UiInheritDirective } from "ui/inherit"
import { EditorPublishStatusComponent } from "./fields/editor-publish-status.component"

@Component({
  imports: [
    UiInheritDirective,
    EditorLocationComponent,
    EditorLayoutLayerComponent,
    EditorLayoutPositionComponent,
    EditorLayoutRotationComponent,
    EditorLayoutWidthComponent,
    EditorInteractiveDetailsComponent,
    EditorClassificationComponent,
    EditorContentComponent,
    UiCollapseComponent,
    EditorPublishStatusComponent,
  ],
  standalone: true,
  template: `
    <div class="column" style="gap: 0">

      <lib-ui-collapse [first]="!timelineSection()">
        <ng-container ngProjectAs="title-left">
          Content
        </ng-container>
        <ng-container ngProjectAs="content">
          <e2e-editor-content
            context="admin"
            inherit
          />
        </ng-container>
      </lib-ui-collapse>

      <lib-ui-collapse>
        <ng-container ngProjectAs="title-left">
          Location
        </ng-container>
        <ng-container ngProjectAs="content">
          <e2e-editor-location inherit/>
        </ng-container>
      </lib-ui-collapse>

      <lib-ui-collapse>
        <ng-container ngProjectAs="title-left">
          Layout
        </ng-container>
        <ng-container ngProjectAs="content">
          <div class="column">
            <e2e-editor-layout-width inherit/>
            <e2e-editor-layout-rotation inherit/>
            <e2e-editor-layout-layer inherit/>
            <e2e-editor-layout-position axis="horizontal" inherit/>
            <e2e-editor-layout-position axis="vertical" inherit/>
          </div>
        </ng-container>
      </lib-ui-collapse>

      <lib-ui-collapse [last]="true">
        <ng-container ngProjectAs="title-left">
          Status
        </ng-container>
        <ng-container ngProjectAs="content">
          <div class="column">
            <e2e-editor-interactive-details inherit/>
            <e2e-editor-publish-status inherit/>
            <!--
                        <e2e-editor-status-options/>
            -->
            <e2e-editor-classification inherit/>
<!--
            @if (showRegion()) {
              <e2e-editor-region inherit/>
            }
-->
          </div>
        </ng-container>
      </lib-ui-collapse>

    </div>
  `,
  selector: "e2e-editor-admin",
})
export class EditorAdminComponent {
  private editorService = inject(EditorService)
  private routeService = inject(RouteService)

  // close = output()
  // resubmitForReview = output()
  // approveAndClose = output()

  timelineSection = computed(() => this.routeService.section().TIMELINE)
  showRegion = computed(() =>
    this.editorService.editorContent()?.status.classification === Classification.REGIONAL
  )
}
