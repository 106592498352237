import { Component, computed, inject, input, signal } from "@angular/core"
import { ImageView } from "../fields.type"
import { TimelineSvgGraphicsService } from "../../../pages/timeline/timeline.svg-graphics.service"
import { NgOptimizedImage } from "@angular/common"
import { StorageService } from "@shared"

@Component({
  imports: [
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    @if (imageView()) {
      <img
        [src]="fileUrl()"
        [alt]="imageView().alt || 'image'"
        style="position: relative; object-fit: contain"
      />
    }
  `,
  selector: "e2e-image-view",
})
export class ImageViewComponent {
  private svgGraphicsService = inject(TimelineSvgGraphicsService)
  private storageService = inject(StorageService)

  imageView = input.required<ImageView>()
  width = computed(() => (this.imageView().contentWidth >= 0 ? this.imageView().contentWidth : undefined))
  height = signal(undefined)
  fileUrl = computed(
    () =>
      // this.domSanitizer.bypassSecurityTrustResourceUrl(
      // this.svgGraphicsService.svgGraphic(this.imageView().svgGraphics) &&
    this.storageService.getFileUrl(
      this.svgGraphicsService.svgGraphic(this.imageView().svgGraphics) ||
      this.imageView().filePath,
    )
    // ),
  )
}
