import { Component, inject, input, output, signal } from "@angular/core"
import { UiDialogComponent } from "ui/dialog"
import { MapService } from "../map/map.service"
import { UiIconComponent } from "ui/icon"
import { UiInheritDirective } from "ui/inherit"
import { ProfileService } from "../profile/profile.service"
import { JsonPipe } from "@angular/common"
import { faBug } from "@fortawesome/pro-solid-svg-icons"

@Component({
  imports: [
    UiInheritDirective,
    UiIconComponent,
    UiDialogComponent,
    JsonPipe,
  ],
  standalone: true,
  template: `
    @if (showMapModal()) {
      <lib-ui-dialog (cancelAndClose)="hideMapModal.emit()">

        <div ngProjectAs="title">
          <div class="row-center w-full">
            Redlining Area Description
            @if (isAdmin()) {
              <button
                class="btn btn-sm btn-ghost text-primary text-2xl"
                [class]="showDescriptionData() ? 'outline outline-2' : ''"
                (click)="showDescriptionData.set(!showDescriptionData())"
              >
                <lib-ui-icon [icon]="faBug"/>
              </button>
            }
          </div>
        </div>

        <div ngProjectAs="content">
          <div
            style="transition: all 0.5s ease-out"
            [style.opacity]="showContent() ? 1 : 0"
            (click)="$event.preventDefault()"
          >

            <div class="column" [style.opacity]="showContent() ? 1 : 0">
              <div class="column">
                @if (!regionProperties()) {
                  <div class="center">
                    This area does not have any
                    <a
                      href=""
                      style="text-decoration: underline"
                      (click)="showAttributionModal.emit()"
                    >
                      HOLC data.
                    </a>
                  </div>
                }
                @if (regionProperties(); as region) {
                  <div class="column p-4 shadow-lg shadow-spread-3 shadow-dark bg-[#f5f0e8] font-mono text-neutral">
                    <div class="center">
                      <a
                        href=""
                        class="underline"
                        (click)="showAttributionModal.emit()"
                      >
                        Home Owners' Loan Corporation
                      </a>
                      <div class="text-xs">
                        partial transcription from 1935-1940
                      </div>
                    </div>

                    <div class="column gap-4 p-8">
                      @if (showDescriptionData()) {
                        <pre>{{ regionProperties() | json }}</pre>
                      }
                      @if (!showDescriptionData()) {
                        <div>
                          NAME OF CITY:
                          {{ region.city }}
                          <!--          ({{ region.location || region.name }})-->
                        </div>
                        <div>
                          SECURITY GRADE:
                          {{ region.security_grade }}
                        </div>
                        <div>
                          AREA NO.:
                          {{ region.area_number }}
                        </div>
                        <div>
                          DESCRIPTION OF TERRAIN:
                          {{ region.description_of_terrain }}
                        </div>
                        @if (region.favorable_influences) {
                          <div>
                            FAVORABLE INFLUENCES:
                            {{ region.favorable_influences }}
                          </div>
                        }
                        @if (region.detrimental_influences) {
                          <mark>
                            DETRIMENTAL INFLUENCES:
                            {{ region.detrimental_influences }}
                          </mark>
                        }
                        @if (region.occupation_or_type) {
                          <div class="column gap-0">
                            <div>
                              INHABITANTS:
                            </div>
                            <div class="pl-8">
                              a. Type:
                              {{ region.occupation_or_type }}
                            </div>
                            <div class="pl-8">
                              b. Estimated Annual Family Income:
                              {{ region.estimated_annual_family_income }}
                            </div>
                            <div class="pl-8">
                              c. Foreign-Born: Nationality:
                              {{ region.foreign_born_nationality }}; {{ region.foreign_born_percent }}%
                            </div>
                            <mark class="pl-8">
                              d. Negro (Yes or No):
                              {{ region.negro_yes_or_no }};
                              {{ region.negro_percent }}%
                            </mark>
                            <mark class="pl-8">
                              e. Infiltration of:
                              {{ region.infiltration_of }}
                            </mark>
                          </div>
                        }
                        @if (region.occupancy) {
                          <div class="column gap-0">
                            <div>
                              OCCUPANCY:
                            </div>
                            <div class="pl-8">
                              a. Land:
                              {{ region.occupancy }}%
                            </div>
                            <div class="pl-8">
                              b. Dwelling units:
                              {{ region.dwelling_units }}%
                            </div>
                            <div class="pl-8">
                              c. Home ownership:
                              {{ region.home_ownership }}%
                            </div>
                          </div>
                        }
                        @if (region.availability_of_mortgage_funds_home_purchase) {
                          <div class="column gap-0">
                            <div>
                              AVAILABILITY OF MORTGAGE FUNDS:
                            </div>
                            <div class="pl-8">
                              a. Home purchase:
                              {{ region.availability_of_mortgage_funds_home_purchase }};
                            </div>
                            <div class="pl-8">
                              b. Home building:
                              {{ region.availability_of_mortgage_funds_home_building }}
                            </div>
                          </div>
                        }
                        <div>
                          TREND OF DESIRABILITY: Next 10-15 years:
                          {{ region.trend_of_desirability }}
                        </div>
                        <div style="text-indent: -20px">
                          <div style="padding-left: 20px">
                            CLARIFYING REMARKS:
                            {{ region.clarifying_remarks }}
                          </div>
                        </div>
                        @if (region.information_obtained_from) {
                          <div>
                            Information for this form was obtained from
                            {{ region.information_obtained_from }}
                          </div>
                        }
                        @if (region.information_obtained_month_and_day) {
                          <div>
                            Date:
                            {{ region.information_obtained_month_and_day }}
                            @if (region.information_obtained_193x) {
                              193{{ region.information_obtained_193x }}
                            }
                          </div>
                        }
                      }
                    </div>
                  </div>
                }
                <div style="text-align: right; font-size: 12px; font-style: italic">
                  \u2014 data from the
                  <a
                    href=""
                    style="text-decoration: underline"
                    (click)="showAttributionModal.emit()"
                  >
                    Mapping Inequality Project
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>

      </lib-ui-dialog>
    }

    <!--
          <ng-template pTemplate="header">
            <lib-ui-icon [icon]="faArrowsAlt"/>
            <div class="center wide">
              Learn - Discover - Share
            </div>
          </ng-template>
          <ng-template pTemplate="footer"/>
    -->
  `,
  selector: "e2e-redline-map-details",
})
export class RedlineMapDetailsComponent {
  private redlineMapService = inject(MapService)
  private profileService = inject(ProfileService)

  isAdmin = this.profileService.isAdmin

  showMapModal = input.required()
  showContent = input.required()
  hideMapModal = output<void>()
  showAttributionModal = output<void>()
  createNewContent = output<void>()

  showDescriptionData = signal(false)
  regionProperties = this.redlineMapService.regionProperties
  storyProperties = this.redlineMapService.storyProperties

  protected readonly faBug = faBug
}

/**
 *
 *
 *   styles: `
 *     .number {
 *       border: 1px solid white;
 *       border-radius: 2em;
 *       width: 2em;
 *       height: 2em;
 *       line-height: 2em;
 *       text-align: center;
 *       font-size: 1.2em;
 *       color: white;
 *       box-sizing: border-box;
 *       flex: 0 0 2em;
 *     }
 *
 *     .header {
 *       font-size: 1.2em;
 *       line-height: 2em;
 *       font-weight: bold;
 *       color: white;
 *       flex: 1;
 *     }
 *   `,
 *
 *
 *         <!--
 *                 <b>
 *                   {{ region.location || region.name }}
 *                   {{ region.security_grade }}
 *                   {{ region.area_number }}
 *                   {{ region.date }}
 *                 </b>
 *                 <p>
 *                   <i>
 *                     Class and Occupation
 *                   </i>
 *                   <br/>
 *                   {{ region.occupation_or_type }}
 *                 </p>
 *                 <p>
 *                   <i>
 *                     Foreign Families (Nationality)
 *                   </i>
 *                   <br/>
 *                   {{ region.foreign_born_percent }}
 *                 </p>
 *                 <p>
 *                   <i>
 *                     Negro Infiltration
 *                   </i>
 *                   <br/>
 *                   {{ region.negro_percent || '-' }}
 *                 </p>
 *                 <p>
 *                   <i>
 *                     Shifting or Infiltration
 *                   </i>
 *                   <br/>
 *                   {{ region.infiltration_of || '-' }}
 *                 </p>
 *                 <p>
 *                   <i>
 *                     Description
 *                   </i>
 *                   <br/>
 *                   {{ region.clarifying_remarks }}
 *                 </p>
 *         -->
 *
 *
 *
 *             <!--
 *             <div
 *               class="column red-paper-texture"
 *               style="padding: 10px; width: 320px"
 *             >
 *
 *               &lt;!&ndash;
 *                         @if (learnMode() || !handset() || true) {
 *                           <section
 *                             class="column"
 *                             [style.flex]="learnMode() ? 1 : 0"
 *                           >
 *                             <div
 *                               class="row"
 *                               (click)="switchToLearnMode()"
 *                             >
 *                               <div class="number">
 *                                 1
 *                               </div>
 *                               <div class="header">
 *                                 Learn about Redlining
 *                               </div>
 *                             </div>
 *                             @if (learnMode()) {
 *                               <e2e-local-map-learn/>
 *                             }
 *                           </section>
 *                         }
 *               &ndash;&gt;
 *
 *               &lt;!&ndash;
 *                         @if (storyMode() || !handset() || true) {
 *                           <section
 *                             class="column"
 *                             [style.flex]="storyMode() ? 1 : 0"
 *                           >
 *                             <div
 *                               class="row"
 *                               (click)="switchToStoryMode()"
 *                             >
 *                               <div class="number">
 *                                 2
 *                               </div>
 *                               <div class="header">
 *                                 Tell Your Story
 *                               </div>
 *                             </div>
 *                             @if (storyMode()) {
 *                               <e2e-local-map-story/>
 *                             }
 *                           </section>
 *                         }
 *               &ndash;&gt;
 *
 *               &lt;!&ndash;
 *                         @if (discoverMode() || !handset() || true) {
 *                           <section
 *                             class="column"
 *                             [style.flex]="discoverMode() ? 1 : 0"
 *                           >
 *                             <div
 *                               class="row"
 *                               (click)="switchToDiscoverMode()"
 *                             >
 *                               <div class="number">
 *                                 3
 *                               </div>
 *                               <div class="header">
 *                                 Discover Stories
 *                               </div>
 *                             </div>
 *                             @if (discoverMode()) {
 *                               <e2e-local-map-discover/>
 *                             }
 *                           </section>
 *                         }
 *               &ndash;&gt;
 *
 *               <button routerLink="/timeline" style="margin-top: auto">
 *                 <e2e-next-section-button/>
 *               </button>
 *
 *             </div>
 *     -->
 */
