import { Component, computed, ElementRef, inject, input, output, signal, viewChild } from "@angular/core"
import { FirestoreReadService } from "../content/firestore-read.service"
import { RouteService } from "../../services/route.service"
import { faAngleLeft, faAngleRight, faTag } from "@fortawesome/pro-solid-svg-icons"
import { ShareYourStoryComponent } from "../shared/share-your-story.component"
import { Story } from "../content/content.model"
import { ProfileService } from "../profile/profile.service"
import { FileNamePipe, StorageService } from "@shared"
import { StoryLinkedStoryComponent } from "./story-linked-story.component"
import { UiDialogComponent } from "ui/dialog"
import { environment } from "../../../environments/environment"
import { UiIconComponent } from "ui/icon"
import { UiInheritDirective } from "ui/inherit"
import { EditorService } from "../editor/services/editor.service"
import { SectionEnum } from "../shared/fields/fields.type"
import { VideoViewComponent } from "../shared/fields/video/video-view.component"

@Component({
  imports: [
    FileNamePipe,
    ShareYourStoryComponent,
    UiInheritDirective,
    UiIconComponent,
    StoryLinkedStoryComponent,
    UiDialogComponent,
    VideoViewComponent,
  ],
  standalone: true,
  template: `
    @if (!!story()) {

      <lib-ui-dialog (cancelAndClose)="close.emit()">
        <div ngProjectAs="title">
          <div
            class="line-clamp-1"
            style="font-size: 24px; font-weight: 600"
            [style.cursor]="showSelectedStory() ? 'pointer' : ''"
            (click)="deselectStory()"
          >
            {{ title() }}
          </div>
        </div>

        <div ngProjectAs="content">
          <div
            class="row p-5 gap-8"
            style="align-items: flex-start"
          >

            <div
              class="column flex-1"
              style="min-width: 300px"
              (click)="deselectStory()"
            >
              @if (title()) {
                <div class="center">
                  {{ title() }}
                </div>
              }
              @if (!videoView().src) {
                @if (imageView().src) {
                  <div>
                    <img [src]="imageView().src | sanitize: 'resourceUrl'" [alt]="imageView().alt"/>
                  </div>
                }
              }
              @if (videoView().src) {
                <e2e-video-view [videoUrl]="videoView().src"/>
              }
              @if (subTitle()) {
                <div class="italic">
                  {{ subTitle() }}
                </div>
              }
              @if (body()) {
                <div class="italic">
                  {{ body() }}
                </div>
              }
              @if (note()) {
                A Note from the Curator
                <div class="italic">
                  {{ note() }}
                </div>
              }
            </div>

            <div
              class="column flex-1"
              style="min-width: 300px"
            >
              <div class="row text-xl font-black">
                <div class="row-center border-2 border-base-content rounded-full" style="width: 34px">
                  <lib-ui-icon [icon]="faTag" class="text-primary"/>
                </div>
                Stories from the Community
              </div>

              <e2e-share-your-story (createNewContent)="createNewContent.emit()"/>

              <div class="column gap-2">
                @for (linkedStory of linkedStories_array(); track linkedStory.id) {
                  <e2e-story-linked-story [story]=" linkedStory"/>
                }
              </div>
            </div>

          </div>
        </div>
      </lib-ui-dialog>

    }
  `,
  selector: "e2e-story-details",
})
export class StoryDetailsComponent {
  private storageService = inject(StorageService)
  private routeService = inject(RouteService)
  private editorService = inject(EditorService)
  private firestoreReadService = inject(FirestoreReadService)
  private profileService = inject(ProfileService)

  private elementRef = viewChild<ElementRef<HTMLElement>>("Slider")
  contentId = input.required<string>()

  close = output()
  createNewContent = output<void>()

  redPaperTexture = signal("url('" + environment.imagekit_urlEndpoint + "/assets/images/red-paper-texture.png')")

  path = computed(() => {
    return "polygon(0% 20%, 30% 0%, 70% 0%, 100% 20%, 100% 100%, 0% 100%)"
  })
  story = computed(() => this.firestoreReadService.getStoryById(this.contentId())) // .getStoryById(this.routeService.timelineIdFromUrl()))
  title = computed(() => this.story()?.[SectionEnum.TITLE].value || "")
  subTitle = computed(() => this.story()?.[SectionEnum.SUBTITLE].value || "")
  body = computed(() => this.story()?.[SectionEnum.BODY].value || "")
  note = computed(() => this.story()?.[SectionEnum.NOTE].value || "")
  imageView = computed(() => {
    const image = this.story()?.[SectionEnum.IMAGE]
    return {
      alt: image?.alt || "",
      src: (image?.filePath && this.storageService.getFileUrl(image.filePath)) || "",
    }
  })
  videoView = computed(() => {
    const video = this.story()?.[SectionEnum.VIDEO]
    return {
      src: (video?.filePath && this.storageService.getFileUrl(video.filePath)) || "",
    }
  })
  linkedStories_array = computed(() => {
    const links_set = this.firestoreReadService.allContentLinks_map().get(this.contentId()) // .get(this.modalStory()?.id || "")
      return links_set && Array.from(links_set.values())
        .map((contentId) => this.firestoreReadService.getStoryById(contentId))
        .filter((story): story is Story => Boolean(story))
        || [] as Story[]
  })
  myContentCount = computed(
    () =>
      this.firestoreReadService
        .arrayFromStories()
        .filter((story) => story.created.userId === this.profileService.userId()).length,
  )

  selectedStory = signal<Story | null>(null)
  showSelectedStory = signal(false)
  selectedStoryImageView = computed(() => {
    const image = this.selectedStory()?.[SectionEnum.IMAGE]
    return {
      alt: image?.alt || "",
      src: (image?.filePath && this.storageService.getFileUrl(image.filePath)) || "",
    }
  })

  scrollLeft = signal<number>(0)
  scrollWidth = signal<number>(0)
  clientWidth = signal<number>(0)
  enableLeftArrow = computed(() => this.scrollLeft() > 0)
  enableRightArrow = computed(() => this.scrollLeft() <= this.scrollWidth() - this.clientWidth())

  closeModal() {
    this.routeService.removeFragment()
  }

  visibleChange(event: boolean) {
    this.deselectStory()
    if (!event) {
      this.closeModal()
    }
  }

  createTimeline_CONTRIBUTED() {
    const fromContent = this.firestoreReadService.getContentById(this.contentId()) // .getContentById(this.modalStory()?.id)
    if (fromContent) {
      this.editorService.createTimelineContent(fromContent)
      // this.timelineService.createTimeline(Classification.CONTRIBUTED, fromContent,)
    }
  }

  slideLeft() {
    const element = this.elementRef()?.nativeElement
    if (element) {
      element.scrollTo({ left: element.scrollLeft - 300, behavior: "smooth" })
      this.scrollLeft.set(element.scrollLeft - 300)
      this.scrollWidth.set(element.scrollWidth)
      this.clientWidth.set(element.clientWidth)
    }
  }

  slideRight() {
    const element = this.elementRef()?.nativeElement
    if (element) {
      element.scrollTo({ left: element.scrollLeft + 300, behavior: "smooth" })
      this.scrollLeft.set(element.scrollLeft + 300)
      this.scrollWidth.set(element.scrollWidth)
      this.clientWidth.set(element.clientWidth)
    }
  }

  selectStory(story: Story) {
    this.selectedStory.set(story)
    this.showSelectedStory.set(true)
  }

  deselectStory() {
    this.showSelectedStory.set(false)
  }

  protected readonly faAngleLeft = faAngleLeft
  protected readonly faAngleRight = faAngleRight
  protected readonly faTag = faTag
}
