import { Component, computed, input, signal } from "@angular/core"
import { RouterLink } from "@angular/router"
import { IntroGradeData } from "../intro.model"
import { NgOptimizedImage } from "@angular/common"

@Component({
  imports: [
    RouterLink,
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    @if (grade(); as data) {
      <div
        style="cursor: pointer; position: relative; brightness(120%)"
        [routerLink]="[]"
        [fragment]="data.grade.toLowerCase()"
        queryParamsHandling="preserve"
        (mouseover)="hover.set(true)"
        (mouseout)="hover.set(false)"
      >
        <img
          disableOptimizedSrcset
          [alt]="data.imageMap.altText"
          [ngSrc]="data.imageMap.map_src"
          style="transition: all 0.5s ease-out; position: relative"
          [style.filter]="hover() ? 'opacity(0.1)' : scrollOpacity()"
          fill
        >
        <!--
                  style="height: unset"
                  [style.width]="data.imageMap.style.width"
                  [width]="data.imageMap.width"
                  [height]="data.imageMap.height"
        -->
        <div style="position: absolute; top: 1px; left: 0; width: 100%; brightness(120%)">
          <img
            [alt]="data.imageMap.altText"
            [ngSrc]="data.imageMap.zone_src"
            style="position: relative"
            fill
          >
        </div>
      </div>
    }
  `,
  selector: "e2e-intro-grade",
})
export class IntroGradeComponent {
  grade = input.required<IntroGradeData>()
  visiblePercent = input<number>(1)
  hover = signal(false)

  scrollOpacity = computed(() => {
    const opacity = Math.abs(this.visiblePercent() - 1.5)
    // console.log(opacity)
    if (opacity < 0.5 ) return "opacity(0.5)"
    return "opacity(" + opacity * 1.5 + ")"
  })
}
