import { Component, computed, inject, input, signal } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { EditorContext, EditorService } from "../../../editor/services/editor.service"
import { StorageService } from "@shared"
import { ref, Storage, TaskState, uploadBytesResumable } from "@angular/fire/storage"
import { FieldType, Video, VideoFileType } from "../fields.type"
import { UiIconComponent } from "ui/icon"
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import { VideoViewComponent } from "./video-view.component"

@Component({
  imports: [
    FormsModule,
    UiIconComponent,
    VideoViewComponent,
  ],
  standalone: true,
  template: `
    <div class="column" style="align-content: stretch">
      <div class="row-center">
        <input
          [id]="fileInputId()"
          type="file"
          accept="video/mp4"
          [disabled]="saving()"
          (change)="fileChangeEvent($event)"
        >
      </div>
      @if (uploadRunning()) {
        <div>
          {{ uploadProgress() }}% uploaded
        </div>
      }
      @if (uploadSuccess()) {
        <div class="row">
          100% uploaded
          <lib-ui-icon [icon]="faCheckCircle"/>
        </div>
      }
      @if (!reload()) {
        <e2e-video-view [videoUrl]="videoUrl()"/>
        <button
          class="btn btn-sm"
          (click)="removeVideo()"
        >
          Remove Video
        </button>
      }
    </div>
  `,
  selector: "e2e-video-edit",
})
export class VideoEditComponent {
  private storage = inject(Storage)
  private storageService = inject(StorageService)
  private editorService = inject(EditorService)

  rowId = input.required<string>()
  context = input<EditorContext | undefined>(undefined)

  uploadState = signal<TaskState | undefined>(undefined)
  uploadRunning = computed(() => this.uploadState() === "running")
  uploadSuccess = computed(() => this.uploadState() === "success")

  uploadProgress = signal(0)

  bytesTransferred = signal(0)
  totalBytes = signal(0)

  saving = this.editorService.saving
  fileInputId = computed(() =>
    ["file-input", this.editorService.editorContent()?.id, this.rowId()]
      .join("-"),
  )
  filePath = computed(() =>
    (this.editorService.editorContent()?.rows[this.rowId()] as Video)?.filePath)
  videoUrl = computed<string>(() =>
    this.storageService.getFileUrl(this.filePath())
  )

  fileType: VideoFileType = ""
  fileOriginal: File | undefined

  newFilePath = this.storageService.newVideoFilePath
  reload = signal(false)

  fileChangeEvent(event: Event): void {
    this.uploadState.set(undefined)
    // console.log(event)
    // console.log(event)
    const target = event.target as HTMLInputElement
    const files = target.files
    // console.log(files)
    if (files?.length) {
      const file = files[0]
      const fileType = file.type as VideoFileType
      /**
       * only use the first file even if multiple are uploaded to the browser
       * check that it is of FileType
       */
      const fileTypes: VideoFileType[] = ["video/mp4"]
      if (fileTypes.includes(fileType)) {
        this.fileType = fileType
        this.fileOriginal = file
        this.storeVideo(file)
        console.log("fileChangeEvent()", file)
      }
    }
  }

  storeVideo(video: File): void {
    /**
     * Upload image to storage.
     */
    const newFilePath = this.newFilePath
    if (newFilePath && video) {
      const storageReference = ref(this.storage, newFilePath)
      const uploadTask = uploadBytesResumable(storageReference, video)

      uploadTask.on("state_changed",
        (snapshot) => {
          // Calculate the upload progress percentage
          this.bytesTransferred.set(snapshot.bytesTransferred)
          this.totalBytes.set(snapshot.totalBytes)
          this.uploadState.set(snapshot.state)
          const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          this.uploadProgress.set(progress)
          console.log(`Upload is ${progress}% done`)
        },
        (error) => {
          // Handle any errors during the upload
          console.error("Upload failed:", error)
        },
      )


      uploadTask
        .then((snapshot) => {
          if (snapshot) {
            if (snapshot.state === "success") {
              this.uploadState.set(snapshot.state)
              const content = this.editorService.editorContent()
              if (content?.rows[this.rowId()].field === FieldType.VIDEO) {
                this.reload.set(true)
                this.editorService.queueEditor({
                  ...content,
                  rows: {
                    ...content.rows,
                    [this.rowId()]: {
                      ...content.rows[this.rowId()],
                      filePath: newFilePath,
                      fileType: this.fileType || (content.rows[this.rowId()] as Video).fileType,
                      svgGraphics: null,
                    } as Video,
                  },
                })
              }
            }
          }
        })
    }
  }

  removeVideo() {
    const content = this.editorService.editorContent()
    if (content?.rows[this.rowId()].field === FieldType.VIDEO) {
      this.editorService.queueEditor({
        ...content,
        rows: {
          ...content.rows,
          [this.rowId()]: {
            ...content.rows[this.rowId()],
            filePath: "",
            fileType: "",
          } as Video,
        },
      })
    }
  }

  protected readonly faCheckCircle = faCheckCircle
}
