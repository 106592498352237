import { Component, computed, input, output, signal } from "@angular/core"
import { UiDialogComponent } from "ui/dialog"
import { UiIconComponent } from "ui/icon"
import { IconDefinition } from "@fortawesome/angular-fontawesome"

@Component({
  imports: [
    UiDialogComponent,
    UiIconComponent,
  ],
  standalone: true,
  template: `
    <div (click)="$event.stopPropagation()">
      @if (showTriggerButton()) {
        <button
          class="btn btn-sm flex-nowrap"
          [class]="classes()"
          (click)="show.set(true)"
        >
          @if (triggerButtonText()) {
            {{ triggerButtonText() }}
          }
          @if (icon(); as icon) {
            <lib-ui-icon [icon]="icon"/>
          }
        </button>
      }

      @if (show() || !showTriggerButton()) {
        <lib-ui-dialog (cancelAndClose)="show.set(false)">
          <div ngProjectAs="title">
            {{ titleText() }}
          </div>

          <div ngProjectAs="content">

            <div class="column" style="align-items: center">
              <div>
                {{ bodyText() }}
              </div>
              <div class="row">
                <button
                  class="btn btn-sm"
                  (click)="show.set(false)"
                >
                  {{ cancelText() }}
                </button>
                <button
                  class="btn btn-sm"
                  (click)="confirmed.emit()"
                >
                  @if (icon(); as icon) {
                    <lib-ui-icon [icon]="icon"/>
                  }
                  {{ confirmText() }}
                </button>
              </div>
            </div>
          </div>
        </lib-ui-dialog>
      }
    </div>
  `,
  selector: "lib-ui-confirm",
})
export class UiConfirmComponent {
  options = input<{
    showTriggerButton?: boolean
    triggerButtonText?: string
    icon?: IconDefinition
    titleText?: string
    bodyText?: string
    cancelText?: string
    confirmText?: string
    classes?: string[]
  }>({})

  confirmed = output()
  show = signal(false)

  showTriggerButton = computed(() => this.options().showTriggerButton || true)
  triggerButtonText = computed(() => this.options().triggerButtonText || "")
  icon = computed(() => this.options().icon)
  titleText = computed(() => this.options().titleText || "Please Confirm")
  bodyText = computed(() => this.options().bodyText || "Are you sure that you want to do this?")
  cancelText = computed(() => this.options().cancelText || "Cancel")
  confirmText = computed(() => this.options().confirmText || "Confirm")
  classes = computed(() => (this.options().classes || []).join(" "))

}
