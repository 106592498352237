import { Component, computed, inject, input, signal } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { ProfileService } from "../profile.service"
import { ProfileEditorService } from "../profile-editor.service"
import { ProfileSaveIconComponent } from "../../shared/profile-save-icon.component"

@Component({
  imports: [
    FormsModule,
    ProfileSaveIconComponent,
  ],
  standalone: true,
  template: `
    <div class="column gap-0">
      <div class="font-bold">
        In what year were you born?
        <e2e-profile-save-icon [modelChange]="modelChange()"/>
      </div>
      <input
        class="input input-secondary"
        type="tel"
        placeholder="enter your birth year"
        [ngModel]="birthYear()"
        (ngModelChange)="changes($event)"
      />
    </div>
  `,
  selector: "e2e-profile-birth-year",
})
export class ProfileBirthYearComponent {
  private profileService = inject(ProfileService)
  private profileEditorService = inject(ProfileEditorService)

  autosave = input(false)

  birthYear = computed(() => (this.profileService.birthYear() && this.profileService.birthYear().toString()) || "")
  modelChange = signal<number | undefined>(undefined)

  changes(birthYearString: string) {
    this.modelChange.set(Date.now())
    const profile = this.profileEditorService.profileEditor() || this.profileService.profile()
    let birthYear = Number(birthYearString)
    const currentYear = new Date(Date.now()).getFullYear()
    if (birthYear <= 1900 || birthYear > currentYear) {
      birthYear = 0
    }
    if (birthYearString === "") {
      birthYear = 0
    }
    if ((birthYear > 1900 && birthYear < currentYear) || birthYear === 0) {
      this.profileEditorService.saveToProfile(
        profile && {
          ...profile,
          birthYear,
        },
        this.autosave()
      )
    }
  }
}
