import { Component, computed, ElementRef, inject, viewChild } from "@angular/core"
import { IntroService } from "../intro.service"
import { RouteService } from "../../../../services/route.service"
import { UiDialogComponent } from "ui/dialog"
import { NgOptimizedImage } from "@angular/common"
import { PageToolbarService } from "../../../page-toolbar.service"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    UiInheritDirective,
    UiDialogComponent,
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    @if (!!grade()) {
      <lib-ui-dialog (cancelAndClose)="hide()" [offset]="pageToolbarHeight() + 10">
        <div ngProjectAs="title">
          {{ grade()?.title }}
        </div>

        <div ngProjectAs="content">
          @if (grade(); as data) {
            <div
              style="display: flex; gap: 20px; flex-wrap: wrap"
            >
              <div style="flex: 1; min-width: 300px; display: flex; flex-direction: column; gap: 10px; padding: 10px">
                <div style="position: relative; overflow: hidden">
                  <div
                    style="position: absolute; top: 0; right: -50px; transform: rotate(-12deg); width: 200px; height: 200px">
                    <img
                      [ngSrc]="data.imageMap.map_src"
                      [alt]="data.imageMap.altText"
                      style="width: 60%; position: relative"
                      fill=""
                    >
                  </div>
                  <div style="padding: 30px; transform: rotate(12deg)">
                    <img
                      [ngSrc]="data.imagePicture.src"
                      [alt]="data.imagePicture.altText"
                      style="width: 60%; position: relative"
                      fill=""
                    >
                  </div>
                </div>
                @for (copy of data.copyArray; track index; let index = $index) {
                  <div>
                    {{ copy }}
                  </div>
                }
              </div>
              <div style="flex: 1; min-width: 300px">
                <img
                  [ngSrc]="data.imageDescription.src"
                  [alt]="data.imageDescription.altText"
                  style="position: relative"
                  fill=""
                >
              </div>
            </div>
          }
        </div>

      </lib-ui-dialog>
    }
  `,
  selector: "e2e-intro-grade-modal",
})
export class IntroGradeModalComponent {
  private routeService = inject(RouteService)
  private introService = inject(IntroService)
  private pageToolbarService = inject(PageToolbarService)
  pageToolbarHeight = this.pageToolbarService.pageToolbarHeight

  private elementRef = viewChild<ElementRef<HTMLDialogElement>>("modal")
  private element = computed(() => this.elementRef()?.nativeElement)

  fragments = this.introService.fragments
  grades = this.introService.grades
  grade = computed(() => this.grades().find((grade) => grade.grade === this.fragments()[0].toUpperCase()))

  hide() {
    this.routeService.changeRoute([])
  }

}
