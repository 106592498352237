import { Component, computed, inject, input } from "@angular/core"
import { Content } from "../../../content/content.model"
import { ImageViewComponent } from "../../../shared/fields/image/image-view.component"
import { FieldType, Image, ImageView, SectionEnum } from "../../../shared/fields/fields.type"
import { TextComponent } from "../../../shared/fields/text.component"
import { UiInheritDirective } from "ui/inherit"
import { TextAreaView } from "./timeline-item.component"
import { VideoViewComponent } from "../../../shared/fields/video/video-view.component"
import { FirestoreReadService } from "../../../content/firestore-read.service"
import { StorageService } from "@shared"

@Component({
  imports: [
    ImageViewComponent,
    TextComponent,
    UiInheritDirective,
    VideoViewComponent,
  ],
  standalone: true,
  template: `
    <div style="position: relative; min-height: 25px">
      <div
        [style.left.px]="content().settings.position.left.px"
        [style.top.px]="content().settings.position.top.px"
      >
        <div class="column" style="gap: 0; outline: none; position: relative; justify-content: center">
          @if (titleValue()) {
            <e2e-text
              [textStyles]="titleTextStyles()"
              [value]="titleValue()"
              inherit
            />
          }
          @if (subTitleValue()) {
            <e2e-text
              [textStyles]="subTitleTextStyles()"
              [value]="subTitleValue()"
              inherit
            />
          }
          @if (bodyValue()) {
            <e2e-text
              [textStyles]="bodyTextStyles()"
              [value]="bodyValue()"
              inherit
            />
          }
          @if (imageView(); as imageView) {
            @if (imageView.filePath || imageView.svgGraphics) {
              <e2e-image-view
                [imageView]="imageView"
                inherit
              />
            }
          }
          @if (!imageView()?.filePath) {
            @if (videoView(); as videoView) {
              @if (videoView.src) {
                <e2e-video-view
                  [videoUrl]="videoView.src"
                />
              }
            }
          }
          @if (noteValue()) {
            <e2e-text
              [textStyles]="noteTextStyles()"
              [value]="noteValue()"
              inherit
            />
          }
        </div>
      </div>
    </div>
  `,
  selector: "e2e-timeline-item-featured",
})
export class TimelineItemFeaturedComponent {
  content = input.required<Content>()
  textArea_map = input.required<Map<SectionEnum, TextAreaView>>()
  titleTextStyles = computed(() => this.textArea_map().get(SectionEnum.TITLE)?.textStyles || {})
  titleValue = computed(() => this.textArea_map().get(SectionEnum.TITLE)?.value || "")
  subTitleTextStyles = computed(() => this.textArea_map().get(SectionEnum.SUBTITLE)?.textStyles || {})
  subTitleValue = computed(() => this.textArea_map().get(SectionEnum.SUBTITLE)?.value || "")
  bodyTextStyles = computed(() => this.textArea_map().get(SectionEnum.BODY)?.textStyles || {})
  bodyValue = computed(() => this.textArea_map().get(SectionEnum.BODY)?.value || "")
  noteTextStyles = computed(() => this.textArea_map().get(SectionEnum.NOTE)?.textStyles || {})
  noteValue = computed(() => this.textArea_map().get(SectionEnum.NOTE)?.value || "")
  image_map = computed<Map<SectionEnum, ImageView>>(
    () =>
      new Map(
        Object.values(this.content().rows)
          .filter((row) => row.field === FieldType.IMAGE)
          .map((row) => [
            row.section,
            {
              ...(row as Image),
              contentWidth: this.content().settings.width.px,
            },
          ]),
      ),
  )
  imageView = computed(() => this.image_map().get(SectionEnum.IMAGE))
  private firestoreReadService = inject(FirestoreReadService)
  story = computed(() => this.firestoreReadService.getStoryById(this.content().id)) // .getStoryById(this.routeService.timelineIdFromUrl()))
  private storageService = inject(StorageService)
  videoView = computed(() => {
    const video = this.story()?.[SectionEnum.VIDEO]
    return {
      src: (video?.filePath && this.storageService.getFileUrl(video.filePath)) || "",
    }
  })

}
