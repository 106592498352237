import { Component, computed, inject } from "@angular/core"
import { NgOptimizedImage } from "@angular/common"
import { RegionService } from "../../../../regions/region.service"
import { StorageService } from "@shared"

@Component({
  imports: [
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    @if (brandHeader(); as header) {
        <div
          class="column justify-center items-center"
          style="flex: 1; padding-bottom: 100px"
          >
          @if (header.title) {
            <div class="text-6xl font-black">
              {{ header.title }}
            </div>
          }
          @if (header.subTitle) {
            <div class="text-md">
              {{ header.subTitle }}
            </div>
          }
          @if (header.image && header.image.src) {
            <div
              class="p-2 rounded-lg"
              [style.width]="header.style.width"
              >
              <img 
                [src]="brandHeaderImage()" 
                [alt]="header.image.altText" 
              />
            </div>
          }
        </div>
      }
      `,
  selector: "e2e-intro-brand-header",
})
export class IntroBrandHeaderComponent {
  private regionService = inject(RegionService)
  private storageService = inject(StorageService)

  brandHeader = this.regionService.brandHeader
  brandHeaderImage = computed(() => this.storageService.getFileUrl(this.brandHeader()?.image.src))
}
