import { computed, inject, Injectable, signal } from "@angular/core"
import { Auth, getIdTokenResult, User, UserInfo } from "@angular/fire/auth"
import { Unsubscribe } from "@angular/fire/firestore"
import { IdTokenResultWithClaims, Role, UserRecord } from "./data-access-firebase-auth-state.model"
import { map } from "rxjs"
import { HttpClient } from "@angular/common/http"
import { DataAccessFirebaseAuthProfileService } from "./data-access-firebase-auth-profile.service"
import { ProviderType } from "util/auth-provider"
import { UtilEnvironmentService } from "util/environment"

@Injectable({
  providedIn: "root",
})
export class DataAccessFirebaseAuthService {
  private auth = inject(Auth)
  private profileDataAccessService = inject(DataAccessFirebaseAuthProfileService)
  private environmentService = inject(UtilEnvironmentService)
  private httpClient = inject(HttpClient)

  private baseUrl = this.environmentService.getEnvironment().baseUrl + "/api-v1"

  private _afUser = signal<Partial<User> | null | undefined>(undefined)
  afUser = this._afUser.asReadonly()
  afUserDisplayName = computed(() => this.afUser()?.displayName)

  loginDetails = computed(() => {
    const afUser = this.afUser()
    const providerData = afUser?.providerData?.[0]
    let details = {
      name: "",
      email: "",
      provider: "",
    }
    switch (providerData?.providerId) {
      case "password":
        details = {
          name: "", // never populated when providerId is password
          email: afUser?.email || "", // assuming populated when providerId is password
          provider: "Username/Password",
        }
        break
      case "microsoft.com":
        details = {
          name: afUser?.providerData?.[0].displayName || "", // assuming populated when providerId is microsoft.com
          email: afUser?.providerData?.[0].email || "", // assuming populated when providerId is microsoft.com
          provider: "Microsoft",
        }
        break
      case "google.com":
        details = {
          name: afUser?.displayName || "", // assuming populated when providerId is google.com
          email: afUser?.email || "", // found one example where email in not provided
          provider: "Google",
        }
        break
      case "github.com":
        details = {
          name: afUser?.displayName || "", // assuming not provided by github
          email: afUser?.email || "", // assuming not provided by github
          provider: "Github",
        }
        break
    }
    return details
  })

  /**
   * we lost providerData from afUser, now providerId is always "firebase"
   */
  providerData = computed(() => {
    // console.log(this.afUser())
    const providerData = this.afUser()?.providerData?.[0]
    if (providerData) {
      return {
        ...providerData,
      }
    } else {
      return (this.afUser() || {}) as UserInfo
    }
  })
  providerId = computed(() => this.providerData().providerId as ProviderType | undefined)

  afUserEmail = computed(() => this.afUser()?.email || "")
  afUserEmailVerified = computed(() => !!this.afUser()?.emailVerified)
  // afUserIsAnonymous = computed(() => !!this.afUser()?.isAnonymous)
  // afUserPhoneNumber = computed(() => this.afUser()?.phoneNumber || "")
  afUserPhotoUrl = computed(() => this.afUser()?.photoURL || "")
  afUserUid = computed(() => this.afUser()?.uid)

  private _idTokenResultWithClaims = signal<IdTokenResultWithClaims | undefined>(undefined)
  idTokenResultWithClaims = this._idTokenResultWithClaims.asReadonly()
  userInit = signal(false)
  userId = computed(() => this.afUserUid() || "")
  loggedIn = computed(() => this.afUser() && !this.afUser()?.isAnonymous)

  // profile = signal<Profile | undefined>(undefined)

  claims = computed(() => this.idTokenResultWithClaims()?.claims || {})
  // claimsEmail = computed(() => this.claims().email || "")
  // claimsEmailVerified = computed(() => !!this.claims().emailVerified)
  // claimsName = computed(() => this.claims().name || "")
  // claimsPicture = computed(() => this.claims().picture || "")
  // claimsUserId = computed(() => this.claims().userId || "")
  claimsRoles = computed(() => this.claims().roles)
  isAdmin = computed(() =>
    this.claimsRoles()?.includes(Role.ADMIN),
  )
  isUserAdmin = computed(() =>
    this.claimsRoles()?.includes(Role.USER_ADMIN),
  )
  hasUserAdminPermission = computed(() =>
    this.claimsRoles()?.includes(Role.ADMIN) || this.claimsRoles()?.includes(Role.USER_ADMIN),
  )

  signInReported = false
  unsubscribe: Unsubscribe | undefined

  constructor() {
    // effect(() => {
    //   const afUser = this.afUser()
    // })

    // effect(() => {
    //   if (this.idTokenResultWithClaims()) {
    //     console.log("this.edit_FirebaseAuth([Role.ADMIN]) // force ADMIN role")
    //     this.getUserRecord_FirebaseAuth("MNMNR9qSJONUyeSGMrRLqNxpcmy2")
    //       .subscribe(result => console.log(result))
    //     // this.edit_FirebaseAuth([Role.ADMIN], "MNMNR9qSJONUyeSGMrRLqNxpcmy2") // force ADMIN role
    //   }
    // })

    this.idTokenListener()
    this.authStateChangeListener()
  }

  /**
   * monitor user's auth state
   */
  authStateChangeListener() {
    this.auth.onAuthStateChanged((user) => {
      const userData: Partial<User> | null = user && user.toJSON() || null
      this.setAfUser(userData)
    })

  }

  /**
   * monitor user's auth status
   */
  idTokenListener() {
    this.auth.onIdTokenChanged((afUser) => {
      this.userInit.set(true)
      // this.setAfUser(afUser)
      // console.log(afUser)

      if (this.unsubscribe) {
        this.unsubscribe()
        this.unsubscribe = undefined
      }

      if (!afUser || !afUser.uid) {
        this.profileDataAccessService.clearProfile()
        if (this.profileDataAccessService.userAccountUnsubscribe) {
          this.profileDataAccessService.userAccountUnsubscribe()
          this.profileDataAccessService.userAccountUnsubscribe = undefined
        }
        this._idTokenResultWithClaims.set(undefined)
      }
      if (afUser && afUser.uid) {
        this.profileDataAccessService.userAccountSubscribe(afUser)
        // this.analyticsService.setUserId(afUser.uid)
        getIdTokenResult(afUser)
          .then(idTokenResultWithClaims => {
            // console.log(idTokenResultWithClaims)
            this._idTokenResultWithClaims.set(idTokenResultWithClaims as IdTokenResultWithClaims)
          })
          .catch(error => {
            this._idTokenResultWithClaims.set(undefined)
            console.log(error)
          })

      }
    })
  }

  /**
   * one userRecord from Firebase Authentication
   * mapped in cloud function, provides limited data, does not emit on changes
   */
  getUserRecord_FirebaseAuth(uid: string) {
    return this.httpClient.get<{ userRecord: UserRecord }>(this.baseUrl + "/users/" + uid)
      .pipe(
        map(({ userRecord }) => userRecord),
      )
    // .subscribe(userRecord => {
    //   console.log(userRecord)
    // })
  }

  /*
    create_FirebaseAuth(displayName: string, password: string, email: string, roles: Role[]) {
      const url = this.baseUrl
      return this.httpClient
        .post(url, { displayName, password, email, roles })
        .subscribe()
    }
  */

  edit_FirebaseAuth(roles: Role[], userId: string) {
    const url = this.baseUrl + "/users/" + userId
    this.httpClient
      .patch(url, { roles, uid: userId })
      .subscribe({
        next: response => {
          // handle success response
          console.log("HTTP Success", response)
        },
        error: err => {
          // handle error response, very useful error message
          console.log("HTTP Error", err)
        },
      })
  }

  /**
   * all userRecords from Firebase Authentication
   * mapped in cloud function, provides limited data, does not emit on changes
   */

  getUserRecords_FirebaseAuth() {
    return this.httpClient.get<{ userRecords: UserRecord[] }>(this.baseUrl)
      .subscribe(({ userRecords }) => {
        console.log(userRecords)
        // this.userRecords.set(userRecords)
      })
  }

  setAfUser(afUser: Partial<User> | null) {
    this._afUser.set(afUser)
  }

}
