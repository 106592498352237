import { Component, input } from "@angular/core"
import { SkeletonModule } from "primeng/skeleton"
import { Profile } from "data-access/firebase-auth"
import { ProviderType } from "util/auth-provider"

@Component({
  imports: [
    SkeletonModule,
  ],
  standalone: true,
  template: `
    <div class="center">
      <button
        class="btn"
      >
        Log Out
      </button>
    </div>
  `,
  selector: "lib-feature-shared-password-reset",
})
export class FeatureSharedPasswordResetComponent {
  profile = input.required<Profile | undefined>()
  providerId = input.required<ProviderType | undefined>()
  afUserEmail = input.required<string>()

}
