import { Component, computed, inject, signal } from "@angular/core"
import { RouterLink } from "@angular/router"
import { IntroService } from "../intro.service"
import { RouteService } from "../../../../services/route.service"
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons"
import { UiDialogComponent } from "ui/dialog"
import { NgOptimizedImage } from "@angular/common"
import { PageToolbarService } from "../../../page-toolbar.service"
import { UiIconComponent } from "ui/icon"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    RouterLink,
    UiIconComponent,
    UiInheritDirective,
    UiDialogComponent,
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    @if (!!card()) {
      <lib-ui-dialog (cancelAndClose)="onClose()" position="top" [offset]="pageToolbarHeight() + 10">
        <div ngProjectAs="title">
          {{ card()?.title }}
        </div>

        <div ngProjectAs="content">

          @if (card(); as data) {
            <div class="row"
              style="justify-content: space-between; font-size: 20px; font-weight: 900; padding: 0 10px">
              <button
                class="btn btn-sm btn-accent"
                [routerLink]="[]"
                [fragment]="'legacy-for-today-' + data.type.previous.toLowerCase()"
                (click)="$event.preventDefault()"
              >
                <lib-ui-icon [icon]="faAngleLeft"/>
                {{ data.type.previous }}
              </button>
              <button
                class="btn btn-sm btn-accent"
                [routerLink]="[]"
                [fragment]="'legacy-for-today-' + data.type.next.toLowerCase()"
                (click)="$event.preventDefault()"
              >
                {{ data.type.next }}
                <lib-ui-icon [icon]="faAngleRight"/>
              </button>
            </div>

            <div style="float: right; padding: 20px 20px 0 20px">
              <div style="max-width: 350px;">
                <div>
                  <img
                    #Image
                    [ngSrc]="data.image.src"
                    [alt]="data.image.altText"
                    style="position: relative"
                    [style.border-radius.px]="52"
                    fill
                  >
                </div>

              </div>
            </div>

            <div style="padding: 10px">
              @for (copyItem of data.copyArray; track copyItem.value) {
                @if (copyItem.type === 'paragraph') {
                  <div style="padding: 0 0 10px">
                    {{ copyItem.value }}
                  </div>
                }
              }
            </div>
          }
        </div>
      </lib-ui-dialog>
    }
  `,
  selector: "e2e-intro-legacy-cards-modal",
})
export class IntroLegacyCardsModalComponent {
  private routeService = inject(RouteService)
  private introService = inject(IntroService)
  private pageToolbarService = inject(PageToolbarService)
  pageToolbarHeight = this.pageToolbarService.pageToolbarHeight

  fragments = this.introService.fragments
  hover = signal(false)
  cards = this.introService.cards
  card = computed(() =>
    this.cards().find(
      (card) => "legacy-for-today-" + card.type.current.toLowerCase() === this.fragments()[0]?.toLowerCase(),
    ),
  )

  onClose() {
    this.routeService.changeRoute([])
  }

  protected readonly faAngleLeft = faAngleLeft
  protected readonly faAngleRight = faAngleRight
}
